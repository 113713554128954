import React, { Component } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'
import { Popconfirm, Collapse, message, Radio } from 'antd'
import 'antd/dist/antd.css'
import '../../css/DynamicCIDCampaignWidget.css'
import {
  addToSelectedAreacodeCids,
  clearSelectedAreacodeCids,
  getSelectedAreacodeCids,
  removeFromSelectedAreacodeCids,
} from '../../helpers/pureUtilities'
class AreaCIDCampaignWidget extends Component {
  state = {
    campaign_id: '',
    add_cid_area_type: 'one_by_one',
    follow_master: false,
    toggle_checked: 0,
    edit_list: [],
    edit_all_data: [],
    areacode_cids: [],
    can_add_cid: false,
  }
  dt_area_cids = null
  componentDidMount() {
    console.log(this.props.campaign_id, this.props.follow_master)
    this.setState(
      {
        campaign_id: this.props.follow_master
          ? 'Master'
          : this.props.campaign_id,
        follow_master: this.props.follow_master,
      },
      () => {
        if (
          this.state.campaign_id !== '' &&
          this.state.campaign_id !== null &&
          this.state.campaign_id !== undefined
        ) {
          this.doTheAreaWidget()
        }
      }
    )
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.campaign_id !== prevProps.campaign_id ||
      this.state.follow_master !== prevProps.follow_master
    ) {
      this.setState(
        {
          campaign_id: this.props.follow_master
            ? 'Master'
            : this.props.campaign_id,
          follow_master: this.props.follow_master,
        },
        () => {
          if (
            (this.state.campaign_id !== '' &&
              this.state.campaign_id !== null &&
              this.state.campaign_id !== undefined) ||
            this.state.follow_master !== prevProps.follow_master
          ) {
            this.doTheAreaWidget()
          }
        }
      )
    }
  }
  initClientSettings = async () => {
    try {
      const can_add = await fetch(
        '/backend_spa/index.php/Ajax/WhitelistedCID/can_add_cid',
        { method: 'GET', credentials: 'include' }
      )
      const can_add_data = await can_add.json()
      if (can_add_data.status) {
        this.setState({ can_add_cid: can_add_data.can_add_cid == 1 })
      }
    } catch (e) {}
  }
  doTheAreaWidget = () => {
    if (this.state.campaign_id == 'Master') {
      this.initClientSettings()
    }
    if (this.dt_area_cids == null) {
      window.oooTable = this.dt_area_cids = window.dttable(
        'dt_area_cids',
        this.props.baseUrl +
          '/index.php/Campaigns/Ajax?action=list_areacode_cids&follow_master=' +
          (this.state.follow_master ? 'Y' : 'N') +
          '&campaign_id=' +
          this.state.campaign_id,
        ''
      )
      /****Below is delete control for Area CIDs */
      var Aurl = this.props.baseUrl
      var dtable_area_cids = this.dt_area_cids

      var blockUI = this.props.blockUI
      $('#dt_area_cids').on('click', '.btn-danger', function () {
        var childKey = $(this).attr('delete_id')
        blockUI(true)
        fetch(Aurl + '/index.php/Campaigns/AreaCID/delete/' + childKey, {
          method: 'GET',
          credentials: 'include',
        })
          .then((blob) => {
            return blob.json()
          })
          .then((data) => {
            console.log(data)
            if (data.status) {
              window.displayAlert('#alert_container', data.msg, 'success')
            } else {
              window.displayAlert('#alert_container', data.msg, 'danger')
            }
            dtable_area_cids.api().ajax.reload()
            blockUI(false)
          })
          .catch((e) => {
            blockUI(false)
          })
      })
      try {
        $('#dt_area_cids').on('change', '.cid_toggler', async (e) => {
          this.props.blockUI(true)
          try {
            var childKey = $(e.target).attr('cid_id')
            const add = $(e.target).is(':checked') ? 1 : 0
            const res = await fetch(
              this.props.baseUrl + '/index.php/Campaigns/post_process',
              {
                credentials: 'include',
                method: 'post',
                body: new URLSearchParams({
                  cid_id: childKey,
                  action: 'toggle_areacode_cid',
                  toggle_to: add,
                }), //new URLSearchParams($("#form_add_dynamic_cid").serialize()),
              }
            )
            const res_j = await res.json()
            if (res_j.status) {
              window.displayAlert('#alert_container', res_j.msg, 'success')
            } else {
              window.displayAlert('#alert_container', res_j.msg, 'danger')
            }
          } catch (e) {
          } finally {
            this.props.blockUI(false)
          }
          // console.log($(e.target), ' ', childKey, ' ', add, ' ', e.target)
        })
      } catch (e) {}
      //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
      dtable_area_cids.api().column(2).visible(!this.state.follow_master)
    } else {
      //table.ajax.url( 'newData.json' ).load();
      this.dt_area_cids
        .api()
        .ajax.url(
          this.props.baseUrl +
            '/index.php/Campaigns/Ajax?action=list_area_cids&follow_master=' +
            (this.state.follow_master ? 'Y' : 'N') +
            '&campaign_id=' +
            this.state.campaign_id
        )
        .load()
      this.dt_area_cids.api().column(2).visible(!this.state.follow_master)
    }
  }
  /*************************Adding area cid code */
  handleaddareacid = (e) => {
    e.preventDefault()
    if (!this.state.can_add_cid) {
      return
    }
    // this.props.blockUI(true)
    // fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
    //   credentials: 'include',
    //   method: 'post',
    //   body: new FormData(e.target), //new URLSearchParams($("#form_add_area_cid").serialize()),
    // })
    //   .then((blob) => {
    //     return blob.json()
    //   })
    //   .then((data) => {
    //     console.log(data)
    //     if (data.status) {
    //       window.displayAlert('#alert_container', data.msg, 'success')
    //     } else {
    //       window.displayAlert('#alert_container', data.msg, 'danger')
    //     }
    //     window.$('#modal_add_area_cid').modal('hide')
    //     this.props.blockUI(false)
    //     this.dt_area_cids.api().ajax.reload()
    //   })
    //   .catch((e) => {
    //     this.props.blockUI(false)
    //   })
    e.preventDefault()
    if (!this.state.can_add_cid) {
      return
    }
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl + '/index.php/Ajax/WhitelistedCID/add_whitelisted_cid',
      {
        credentials: 'include',
        method: 'post',
        body: new FormData(e.target), //new URLSearchParams($("#form_add_dynamic_cid").serialize()),
      }
    )
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_area_cid').modal('hide')
        this.props.blockUI(false)
        this.dt_area_cids.api().ajax.reload()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  onAddDCChange = (e) => {
    console.log('radio checked', e.target.value)
    this.setState({ add_cid_area_type: e.target.value })
  }
  fetchAreaCodeCids = async () => {
    try {
      const areacode_cids = await fetch(
        `/backend_spa/index.php/Campaigns/Ajax?action=list_all_areacode_cids&campaign_id=${this.state.campaign_id}`,
        { method: 'GET', credentials: 'include' }
      )
      const areacode_cids_p = await areacode_cids.json()

      if (areacode_cids_p.status) {
        this.setState({ areacode_cids: areacode_cids_p.data })
      }
    } catch (e) {}
  }
  displayEditModal = async () => {
    // this.props.blockUI(true)
    // try {
    //   await this.setState({ edit_list: [] })
    //   const res = await fetch(
    //     `${this.props.baseUrl}/index.php/Campaigns/Ajax?action=list_all_areacode_cids&campaign_id=${this.state.campaign_id}`,
    //     {
    //       credentials: 'include',
    //       method: 'get',
    //     }
    //   )
    //   const res_j = await res.json()
    //   if (res_j.status) {
    //     this.setState({ edit_list: res_j.data }, () => {
    //       window.$('#modal_edit_dynamic_cid').modal('show')
    //     })
    //   } else {
    //   }
    // } catch (e) {
    // } finally {
    //   this.props.blockUI(false)
    // }

    this.props.blockUI(true)
    try {
      await this.setState({ edit_list: [] })
      const res = await fetch(
        `${this.props.baseUrl}/index.php/Ajax/WhitelistedCID/list_all_whitelisted_cids`,
        {
          credentials: 'include',
          method: 'get',
        }
      )
      const res_j = await res.json()
      if (res_j.status && res_j?.data) {
        await this.fetchAreaCodeCids()

        const gola = []

        res_j?.data?.forEach((el) => {
          const ractive = this.state.areacode_cids.find((e) => e.cid == el.cid)
            ? 1
            : 0
          if (ractive) {
            gola.push({
              ...el,
              is_active: ractive,
            })
          } else {
            gola.unshift({
              ...el,
              is_active: ractive,
            })
          }
        })
        // gola.sort((a, b) => a.active)
        this.setState({ edit_list: gola, edit_all_data: gola }, () => {
          window.$('#modal_edit_dynamic_cid').modal('show')
        })
      } else {
      }
    } catch (e) {
    } finally {
      this.props.blockUI(false)
    }
  }
  toggleCids = async (act) => {
    // if (getSelectedAreacodeCids().length == 0) {
    //   return
    // }
    // this.props.blockUI(true)
    // try {
    //   const res = await fetch(
    //     this.props.baseUrl + '/index.php/Campaigns/post_process',
    //     {
    //       credentials: 'include',
    //       method: 'post',
    //       body: new URLSearchParams({
    //         cid_ids: JSON.stringify(getSelectedAreacodeCids()),
    //         action: 'toggle_areacode_cids',
    //         toggle_to: act,
    //       }), //new URLSearchParams($("#form_add_dynamic_cid").serialize()),
    //     }
    //   )
    //   const res_j = await res.json()
    //   if (res_j.status) {
    //     window.displayAlert('#alert_container', res_j.msg, 'success')
    //   } else {
    //     window.displayAlert('#alert_container', res_j.msg, 'danger')
    //   }
    // } catch (e) {
    // } finally {
    //   this.props.blockUI(false)
    //   this.dt_area_cids.api().ajax.reload()
    //   clearSelectedAreacodeCids()
    // }
  }
  /***************************************************** */
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          {/****Area CID portlet */}

          <div className="portlet portlet-sortable light bordered">
            <div className="portlet-title">
              <div className="caption font-green-sharp">
                <i className="icon-speech font-green-sharp"></i>
                <span className="caption-subject bold "> AC-CIDs</span>
                <span className="caption-helper font-red">
                  <br /> template to upload csv is{' '}
                  <a target="_blank" href="lp_template.csv">
                    here
                  </a>
                  .
                </span>
              </div>
              <div className="actions">
                {this.state.follow_master ? (
                  ''
                ) : (
                  <>
                    <a
                      onClick={(e) => {
                        this.displayEditModal()
                      }}
                      className="btn btn-circle btn-default btn-sm"
                      style={{ marginRight: '4px' }}
                    >
                      <i className="fa fa-pencil"></i> Edit{' '}
                    </a>
                    {this.state.can_add_cid && (
                      <a
                        id="modal_AddAreaCID"
                        onClick={(e) => {
                          window.$('#modal_add_area_cid').modal('show')
                        }}
                        className="btn btn-circle btn-default btn-sm"
                      >
                        <i className="fa fa-plus"></i> Add{' '}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="portlet-body">
              <table
                className="table table-striped table-bordered table-advance table-hover"
                id="dt_area_cids"
              >
                <thead>
                  <tr>
                    <th>Caller ID</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>

          {/***** */}
        </div>
        {/*<!-----------Area CID Modal to Add------------------->*/}
        <div
          className="modal fade draggable-modal"
          id="modal_add_area_cid"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">ADD NEW Area CID</h4>
              </div>

              <form
                className="form-horizontal"
                onSubmit={this.handleaddareacid}
                id="form_add_area_cid"
                method="post"
                encType="multipart/form-data"
              >
                <input
                  type="hidden"
                  name="action"
                  defaultValue="add_area_cid"
                />
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <input
                  type="hidden"
                  name="add_area_cid_type"
                  value={this.state.add_cid_area_type}
                />
                <div className="modal-body">
                  {/* Lets do this, add multiple option and add by csv */}
                  <Radio.Group
                    onChange={this.onAddDCChange}
                    value={this.state.add_cid_area_type}
                    style={{ marginBottom: '10px' }}
                  >
                    <Radio value="one_by_one">One</Radio>
                    <Radio value="multiple">Mutliple</Radio>
                    <Radio value="csv">Upload CSV</Radio>
                  </Radio.Group>
                  {/******************************* */}
                  {this.state.add_cid_area_type == 'one_by_one' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Area code
                          </label>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="sel2_in_modal form-control"
                              name="areacode"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">CID</label>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="sel2_in_modal form-control"
                              name="cid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Description
                          </label>
                          <div className="col-md-4">
                            <textarea
                              type="text"
                              className="sel2_in_modal form-control"
                              name="description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {this.state.add_cid_area_type == 'multiple' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">CIDs</label>
                          <div className="col-md-4">
                            <textarea
                              name="cids"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {this.state.add_cid_area_type == 'csv' ? (
                    <React.Fragment>
                      <div className="form-body">
                        <div className="form-group">
                          <label className="control-label col-md-3">File</label>
                          <div className="col-md-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="csv_file"
                              name="csv_file"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------------------------------->*/}
        {/*<!-----------Acrea code CID Modal to Add------------------->*/}
        <div
          className="modal fade draggable-modal"
          id="modal_edit_dynamic_cid"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Edit Area Code Caller IDs</h4>
                <input
                  className="form-control inline"
                  style={{
                    marginLeft: '5px',
                    marginRight: '10px',
                    float: 'right',
                    height: '28px',
                    width: '180px',
                  }}
                  onChange={(e) => {
                    try {
                      this.setState(
                        { search_query: e.target.value, edit_list: [] },
                        () => {
                          const temp_dat = []
                          if (this.state.search_query == '') {
                            this.setState({
                              edit_list: this.state.edit_all_data,
                            })
                          } else {
                            this.state.edit_all_data.forEach((el) => {
                              if (
                                el.cid.indexOf(this.state.search_query) !==
                                  -1 ||
                                el.description.indexOf(
                                  this.state.search_query
                                ) !== -1
                              ) {
                                temp_dat.push(el)
                              }
                              this.setState({ edit_list: temp_dat })
                            })
                          }
                        }
                      )
                    } catch (e) {}
                  }}
                />
              </div>
              <div className="modal-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Caller ID</th>
                      <th>Description</th>
                      <th>Status</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.edit_list.map((ci, ind) => (
                      <tr>
                        <td style={{ verticalAlign: 'middle' }}>
                          {/* <input
                            className="form-control"
                            type="text"
                            value={ci.cid}
                            onChange={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids[ind].cid = e.target.value
                              this.setState({ edit_list: all_cids })
                            }}
                          /> */}
                          <label className="label label-default">
                            {ci.cid}
                          </label>
                        </td>
                        <td>
                          <textarea
                            className="form-control"
                            onChange={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids[ind].description = e.target.value
                              this.setState({ edit_list: all_cids })
                            }}
                            value={ci.description}
                          >
                            {ci.description}
                          </textarea>{' '}
                        </td>
                        <td>
                          <label className="switch_dcid">
                            <input
                              onChange={(e) => {
                                const all_cids = this.state.edit_list
                                all_cids[ind].is_active = e.target.checked
                                  ? '1'
                                  : '0'
                                this.setState({ edit_list: all_cids })
                              }}
                              className="cid_toggler"
                              type="checkbox"
                              checked={ci.is_active == '1'}
                            />
                            <div className="slider_dcid round">
                              {/*ADDED HTML */}
                              {ci.is_active == '1' && (
                                <span className="on">ON</span>
                              )}
                              {ci.is_active == '0' && (
                                <span className="off">OFF</span>
                              )}
                              {/*END*/}
                            </div>
                          </label>
                        </td>
                        {/* <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              const all_cids = this.state.edit_list
                              all_cids.splice(ind, 1)
                              this.setState({ edit_list: all_cids })
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={async (e) => {
                    this.props.blockUI(true)
                    try {
                      const resp = await fetch(
                        `${this.props.baseUrl}/index.php/Campaigns/post_process`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          body: new URLSearchParams({
                            edit_list: JSON.stringify(this.state.edit_list),
                            action: 'bulk_edit_areacode_cids',
                            campaign_id: this.props.campaign_id,
                          }),
                        }
                      )
                      const resp_j = await resp.json()
                      if (resp_j.status) {
                        window.displayAlert(
                          '#alert_container',
                          resp_j.msg,
                          'success'
                        )
                      } else {
                        window.displayAlert(
                          '#alert_container',
                          resp_j.msg,
                          'danger'
                        )
                      }
                    } catch (e) {
                      console.log('error raised somehow', e)
                      window.displayAlert(
                        '#alert_container',
                        'Something went wrong',
                        'success'
                      )
                    } finally {
                      this.dt_area_cids.api().ajax.reload()
                      this.props.blockUI(false)
                    }
                  }}
                >
                  Save All
                </button>
              </div>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------------------------------->*/}
      </React.Fragment>
    )
  }
}

export default AreaCIDCampaignWidget
